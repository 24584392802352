<template>
	<div class="row">
      <div class="col-8">
         <div class="card">
            <div class="card-body">
               <div class="d-flex h-100 ml-3">
                  <div class="d-flex align-self-center w-100">
                     <img class="rounded d-flex alifn-self-center my-2 filter" :src="dadosUsuario.avatar != null ? dadosUsuario.avatar : require('@/assets/admin/images/discord.jpeg')" alt="Foto de perfil" width="250">
                     <div class="w-100">
                        <div class="ms-4 my-2">
                           <h3 class="mb-1 limitador filter color-theme">{{ dadosUsuario.nomeCompleto }}</h3>
                           <h5 class="text-secondary limitador filter">{{ dadosUsuario.userName }}</h5>
                        </div>
                        <div class="d-flex col-12 mt-5 filter">
                           <div class="col-4 d-flex justify-content-center">
                              <div class="body">
                                 <div class="h6">{{ $t('admPerfil.cursos') }}</div>
                                 <div class="mt-1 h1 color-theme text-center">{{ dadosUsuario.totalModulos.toString().padStart(2, '0') }}</div>
                              </div>
                           </div>
                           <div class="col-4 d-flex justify-content-center">
                              <div class="body">
                                 <div class="h6">Scripts</div>
                                 <div class="mt-1 h1 color-theme text-center">{{ dadosUsuario.totalProdutos.toString().padStart(2, '0') }}</div>
                              </div>
                           </div>
                           <div class="col-4 d-flex justify-content-center">
                              <div class="body">
                                 <div class="h6">{{ $t('admPerfil.pacotes') }}</div>
                                 <div class="mt-1 h1 color-theme text-center">{{ dadosUsuario.pacote.length.toString().padStart(2, '0') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-4">
         <div class="card">
            <div class="card-body">
               <div class="position-absolute btnDespesas" @click="editDados()">
                  <i class="far fa-pencil font-14 cursor-pointer"></i>
               </div>
               <div class="mt-2 mb-2 ms-3">
                  <div>
                     <small class="text-muted">{{ $t('admPerfil.campoNome') }}: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.nomeCompleto != null && dadosUsuario.nomeCompleto != '' ? dadosUsuario.nomeCompleto : '-'}}</p>
                  </div>
                  <div>
                     <small class="text-muted">User Discord: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.userName != null && dadosUsuario.userName != '' ? dadosUsuario.userName : '-'}}</p>
                  </div>
                  <div>
                     <small class="text-muted">{{ $t('admPerfil.campoCpfCnpj') }}: </small>
                     <span class="mb-2 limitador"> {{dadosUsuario.documento != null && dadosUsuario.documento != '' ? dadosUsuario.documento : '-'}}</span>
                  </div>
                  <div>
                     <small class="text-muted">Email: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.email != null && dadosUsuario.email != '' ? dadosUsuario.email : '-'}}</p>
                  </div>
                  <div>
                     <small class="text-muted">Whatsapp: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.whatsApp != null && dadosUsuario.whatsApp != '' ? dadosUsuario.whatsApp : '-'}}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalDados -->
      <div class="modal fade" id="modalDados" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalDadosLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalDadosLabel">{{ $t('admPerfil.editar') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
               </div>
               <div class="modal-body">
                  <div class="card p-3 mb-0">
                     <div class="row">
                        <div class="col-12 col-lg-8 mb-3">
                           <label><i class="far fa-user color-theme me-1"></i> {{ $t('admPerfil.campoNome') }}</label>
                           <input type="text" class="form-control" v-model="dados.nomeCompleto" />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                           <label><i class="far fa-file color-theme me-1"></i> {{ $t('admPerfil.campoCpfCnpj') }}</label>
                           <input type="text" class="form-control" v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="dados.documento" />
                        </div>
                        <div class="col-12 col-lg-6 mb-3">
                           <label><i class="fab fa-whatsapp color-theme me-1"></i> Whatsapp</label>
                           <input type="text" class="form-control" v-mask="'(##) # ####-####'" v-model="dados.whatsApp" />
                        </div>
                        <div class="col-12 col-lg-6 mb-3">
                           <label><i class="far fa-envelope color-theme me-1"></i> Email</label>
                           <input type="text" class="form-control" maxlength="50" v-model="dados.email" />
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="saveDadosUsuario">{{ $t('admPerfil.botaoSalvar') }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admPerfil.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'Perfil',
	data: function() {
		return {
         pesquisa: {'nome': '', 'resultado': []},
         dados: {'nomeCompleto': null, 'documento': null, 'email': null, 'whatsApp': null}
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
      saveDadosUsuario : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveDadosUsuario',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.dados

         }).then(() => {
            this.$store.dispatch('atualizarDadosUsuario', this.dados)
            $('#modalDados').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t('admPerfil.salvarSucesso')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarCurso').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      editDados : function () {
         this.dados = JSON.parse(JSON.stringify(this.dadosUsuario));
         $('#modalDados').modal('show')
      }
	},
   mounted() {
      this.dados = JSON.parse(JSON.stringify(this.dadosUsuario))
   }
}

</script>

<style scoped>

.btnDespesas {
	font-size: 13px;
	color: var(--dark-color);
	display: flex;
	justify-content: center;
	align-items: center;
	top: 5px;
	right: 5px;
	background-color: transparent;
	width: 40px;
	height: 28px;
	transition: 0.3s;
}

@media (max-width: 768px) {
	.card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		margin: 0;
	}
}

</style>